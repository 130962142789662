import Flickity from 'flickity';
import FlickityFase from 'flickity-fade';

require('flickity-imagesloaded');

let quotes = document.querySelector('.quote-slider');
let quotesNext = document.querySelector('.quote-slider--next');
let quotesPrev = document.querySelector('.quote-slider--prev');
if (quotes) {
	let quoteSlider = new Flickity(quotes, {
		pageDots: false,
		prevNextButtons: false,
		wrapAround: true,
		imagesLoaded: true,
		fade: true
	});

	if (quotesNext) {
		quotesNext.addEventListener("click", function(e) {
			e.preventDefault();
			quoteSlider.next();
		});
	}

	if (quotesPrev) {
		quotesPrev.addEventListener("click", function(e) {
			e.preventDefault();
			quoteSlider.previous();
		});
	}
}

window.addEventListener('load', function() {
	const blocksSliders = document.querySelectorAll('.blocks-slider');

	if (blocksSliders !== null) {
		for (let i = 0; i < blocksSliders.length; i++) {
			const blocksSlider = blocksSliders[i]

			new Flickity(blocksSlider, {
				pageDots: false,
				prevNextButtons: false,
				adaptiveHeight: false,
				cellSelector: '.slide',
				freeScroll: true,
				contain: true,
			});
		}
	}

})



import Vue from 'vue';
import VueSlider from 'vue-slider-component'

// Get calc data
//
import { calcData } from '../shared/calc-data'

const numeral = require("numeral");

Vue.filter("formatCurrency", function (value) {
    return numeral(value).format("0,0.00");
});

Number.isInteger = Number.isInteger || function (value) {
    return typeof value === "number" &&
        isFinite(value) &&
        Math.floor(value) === value;
};

Vue.config.productionTip = false
if (document.querySelector('#calc')) {
    new Vue({
        el: '#calc',
        delimiters: ['${', '}'],

        components: {
            VueSlider
        },

        data: calcData,

        watch: {
            termSliderValue: function (val) {
                this.loanTerm = val;
                this.calculate();

            },
            loanSliderValue: function (val) {
                this.loanValue = val;
                if(val > 15000) this.minTerm = 36;
                else if (val > 5000) this.minTerm = 24;
                else this.minTerm = 12;
                this.calculate();
            }
        },

        created() {
            //SET A SLIDER DEFAULT
            this.loanSliderValue = this.prevLoanValue = this.minLoanValue;
            this.termSliderValue = this.prevLoanTerm = this.minTerm;
        },

        mounted() {
            if (this.$refs.apr) {
                this.apr = this.$refs.apr.value / 100;
            }

            if (this.$refs.enquiryUrl) {
                this.queryStr = window.location.search.replace('?', '');
                this.enquiryUrl = this.$refs.enquiryUrl.value;
            }

            if (this.$refs.defaultLoanValue) {
                let defaultLoanValue = parseInt(this.$refs.defaultLoanValue.value);
                if (defaultLoanValue >= this.minLoanValue && defaultLoanValue <= this.maxLoanValue) {
                    this.loanValue = defaultLoanValue;

                }
            }

            if (this.$refs.defaultLoanTerm && this.$refs.defaultLoanTerm.value) {
                let defaultLoanTerm = parseInt(this.$refs.defaultLoanTerm.value);
                if (defaultLoanTerm >= this.minTerm && defaultLoanTerm <= this.maxTerm) {
                    this.loanTerm = defaultLoanTerm;

                }
            }

            this.loanSliderValue = this.loanValue;
            this.termSliderValue = this.loanTerm;

            for (let ref in this.$refs) {
                this.$refs[ref].parentNode.removeChild(this.$refs[ref]);
            }

            this.calculate();

            document.querySelector('#calc').classList.add('calc-loaded')
        },

        methods: {
            calculate() {
                //CHECK LOAN VALUE
                if (!this.loanValue) {
                    return;
                }

                if (!Number.isInteger(+this.loanValue)) {
                    this.loanValue = this.prevLoanValue;
                    return;
                }

                if (+this.loanValue < this.minLoanValue || +this.loanValue > this.maxLoanValue) {

                    if (+this.loanValue < this.minLoanValue) {
                        this.loanValue = this.minLoanValue;
                        this.loanSliderValue = this.minLoanValue;
                        document.querySelector('#calc .calc__update p').innerHTML = 'Min loan value £' + this.minLoanValue;
                        document.querySelector('#calc .calc__update').classList.add('show')

                        setTimeout(function () {
                            document.querySelector('#calc .calc__update').classList.remove('show')
                        }, 3500);
                    }

                    if (+this.loanValue > this.maxLoanValue) {
                        this.loanValue = this.maxLoanValue;
                        this.loanSliderValue = this.maxLoanValue;
                        document.querySelector('#calc .calc__update p').innerHTML = 'Max loan value £' + this.maxLoanValue;
                        document.querySelector('#calc .calc__update').classList.add('show')

                        setTimeout(function () {
                            document.querySelector('#calc .calc__update').classList.remove('show')
                        }, 3500);
                    }

                    //this.loanValue = this.prevLoanValue;

                    return;
                }

                //CHECK LOAN TERM
                if (!this.loanTerm) {
                    return;
                }

                if (!Number.isInteger(+this.loanTerm)) {
                    this.loanTerm = this.prevLoanTerm;
                    return;
                }

                if (+this.loanTerm < this.minTerm || +this.loanTerm > this.maxTerm) {

                    if (+this.loanTerm < this.minTerm) {
                        this.loanTerm = this.minTerm;
                        this.termSliderValue = this.minTerm;

                        document.querySelector('#calc .calc__update p').innerHTML = 'Min term is ' + this.minTerm + ' months';
                        document.querySelector('#calc .calc__update').classList.add('show')
                        setTimeout(function () {
                            document.querySelector('#calc .calc__update').classList.remove('show')
                        }, 3500);
                    }

                    if (+this.loanTerm > this.maxTerm) {
                        this.loanTerm = this.maxTerm;
                        this.termSliderValue = this.maxTerm;

                        document.querySelector('#calc .calc__update p').innerHTML = 'Max term is ' + this.maxTerm + ' months';
                        document.querySelector('#calc .calc__update').classList.add('show')
                        setTimeout(function () {
                            document.querySelector('#calc .calc__update').classList.remove('show')
                        }, 3500);

                    }

                    //this.loanTerm = this.prevLoanTerm;
                    return;
                }


                this.loanValue = Math.round(this.loanValue / this.loanIncrement) * this.loanIncrement;

                this.loanSliderValue = this.prevLoanValue = this.loanValue;
                this.termSliderValue = this.prevLoanTerm = this.loanTerm;

                const monthlyInterestRate = Math.pow(1 + this.apr, 1 / 12) - 1;

                this.monthlyRepaymentAmount = +((monthlyInterestRate * this.loanValue * Math.pow((1 + monthlyInterestRate), this.loanTerm)) / (Math.pow(1 + monthlyInterestRate, this.loanTerm) - 1)).toFixed(2);
                this.poundval = numeral(this.monthlyRepaymentAmount).format("0,0.00").split('.')[0];
                this.penceval = numeral(this.monthlyRepaymentAmount).format("0,0.00").split('.')[1];

                this.totalRepayableAmount = +(this.monthlyRepaymentAmount * this.loanTerm).toFixed(2);
                this.buttonLink = this.enquiryUrl + "?LoanAmount=" + this.loanValue + "&LoanTermInMonths=" + this.loanTerm + "&" + this.queryStr;

                let buttons = document.querySelectorAll('#calc .update-quote-link');
                for (let i = 0; i < buttons.length; i++) {
                    buttons[i].href = this.enquiryUrl + "?LoanAmount=" + this.loanValue + "&LoanTermInMonths=" + this.loanTerm + "&" + this.queryStr;
                }
            }
        }
    });
}





import ClipboardJS from 'clipboard';

let buttons = document.querySelectorAll('.copy-clipboard');

for (let i = 0; i < buttons.length; i++) {


    let clipboard = new ClipboardJS(buttons[i], {
        container: document.getElementById('copy__hold')
    });

    clipboard.on('success', function(e) {
        let target = buttons[i];
        target.setAttribute('data-tooltip', 'Copied link!');
        setTimeout(() => {
            target.setAttribute('data-tooltip', 'Copy to Clipboard');
        }, 2000);
        console.log(e)
    });

    clipboard.on('error', function(e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
    });
}


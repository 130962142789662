//###########################################################################
// Section Links
//###########################################################################

export const sectionLinks = {
    elements: {
        sectionLinks: null
    },

    init() {
        const self = this

        self.elements.sectionLinks = document.querySelectorAll('.section-link')

        if (self.elements.sectionLinks !== null) {
            for (let i = 0; i < self.elements.sectionLinks.length; i++) {
                let sectionLink = self.elements.sectionLinks[i];

                self.handleSectionLink(sectionLink)
            }
        }
    },

    handleSectionLink(sectionLink) {
        const self = this

        const targetSection = document.querySelector(sectionLink.getAttribute('href'))

        sectionLink.addEventListener('click', function (e) {
            e.preventDefault()

            let elementTop = targetSection.getBoundingClientRect().top
            let scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
            const headerHeight = document.querySelector('#header').clientHeight
            let scrollDistance = elementTop + scrollTop + -headerHeight

            window.scroll({
                top: scrollDistance,
                left: 0,
                behavior: 'smooth'
            });
        })
    },
}

document.addEventListener('DOMContentLoaded', () => {
    sectionLinks.init()
})



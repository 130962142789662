// Accords & Types
let accords = document.querySelectorAll('.accord__item');

// Accords click
for (let i = 0; i < accords.length; i++) {
	accords[i].addEventListener('click', (e) => {

		if (accords[i].classList.contains("open")) {
			for (let i = 0; i < accords.length; i++) {
				accords[i].classList.remove("open");
			}
		} else {
			for (let i = 0; i < accords.length; i++) {
				accords[i].classList.remove("open");
			}
			accords[i].classList.add("open");
		}
	});
}





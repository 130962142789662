import Vue from 'vue';

Vue.config.devtools = false

export default new Vue({
	el: '#header',
	data: {
		isOpen: false,
		scrolled: false,
		dropHelp: false,
		dropLoans: false,
		lastScrollPosition: 0,
		hide: false
	},
	methods: {
		toggle: function() {
			this.isOpen = !this.isOpen
			document.body.classList.toggle('fix');
		},
		toggleLink: function() {
			this.isOpen = false;
			document.body.classList.remove('fix');
			this.dropHelp = false;
			this.dropLoans = false;
		},
		helpDropdown: function() {
			this.dropHelp = !this.dropHelp
		},
		loanDropdown: function() {
			this.dropLoans = !this.dropLoans
		},
		handleScroll(event) {
			const scrollBarHeight = 10;
			const header = document.getElementById("header")
			const banner = document.getElementsByClassName('banner')[0];
			const {offsetHeight} = header;

			if (!banner || window.scrollY > banner.offsetHeight ) {
				header.style.position = "fixed";
				header.style['margin-bottom'] = 0;
			} else {
				header.style.position = 'relative';
				header.style['margin-bottom'] = `-${offsetHeight}px`;
			}

			if (window.scrollY > offsetHeight + scrollBarHeight && !header.classList.contains('.header--scrolled')) {
				this.scrolled = true
			} else if (window.scrollY < offsetHeight + scrollBarHeight) {
				this.scrolled = false
			}

			const currentScrollPosition = window.scrollY || document.documentElement.scrollTop
			if (currentScrollPosition < 0) {
				return
			}
			// Stop executing this function if the difference between
			// current scroll position and last scroll position is less than some offset
			if (Math.abs(currentScrollPosition - this.lastScrollPosition) < scrollBarHeight) {
				return
			}
			this.hide = currentScrollPosition > this.lastScrollPosition
			this.lastScrollPosition = currentScrollPosition
		},

	},
	mounted() {
		// set initial header properties
		this.handleScroll();
	},
	created() {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	}
});

//import Reveal from './functions/scrollReveal';
//import {scrollTo} from './functions/scrollTo';
//import CookieMessage from './components/cookieMessage'
import Accord from './components/accord'
import Sliders from './components/sliders'
import Calc from './components/calc'
import CalcInline from './components/calcInline'
import Navigation from './components/navigation';
import sectionLinks from './components/sectionLinks'
import ImageHandling from './functions/imageHandling'
import copyClipboard from './components/copyClipboard'
import lottie from 'lottie-web';
import Cookies from 'js-cookie'

import Sticky from 'sticky-js'
let sticky = new Sticky('.sticky')

let animationSeen = "logo-seen"
let getSeen = Cookies.get(animationSeen);

if (!getSeen) {

	lottie.loadAnimation({
		container: document.querySelector('#a-logo'), // the dom element that will contain the animation
		renderer: 'svg',
		loop: false,
		autoplay: true,
		path: '/lottie/Oakbrook_Loop.json' // the path to the animation json
	});

	Cookies.set(animationSeen, 1);
}

if (document.querySelector('#cookie-control') !== null) {
    document.querySelector('#cookie-control').addEventListener('click', (e) => {
        e.preventDefault();
        CookieControl.open()
    });
}
